.reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.save__controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.save__controls button {
  margin-left: 5px;
}

.node__controls {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.node__controls button {
  margin-left: 5px;
}

.providerflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.providerflow aside {
  border-left: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background-color: #fff;
}

.providerflow aside .description {
  margin-bottom: 10px;
}

.providerflow aside .title {
  font-weight: 700;
  margin-bottom: 5px;
}

.providerflow aside .transform {
  margin-bottom: 20px;
}

.providerflow .reactflow-wrapper {
  flex-grow: 1;
}

.providerflow .selectall {
  margin-top: 10px;
}

.react-flow__node.selected {
  outline: none;
  border: 1px solid #555;
}

@media screen and (min-width: 768px) {
  .providerflow {
    flex-direction: row;
  }

  .providerflow aside {
    width: 20%;
    max-width: 250px;
    height: 200px;
  }
}
