.toastui-calendar-event-time {
  white-space: normal;
}
.toastui-properate-calendar-day {
  font-size: 18px;
  letter-spacing: -1.8px;
  border-radius: 100%;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
  margin-top: -2px;
}
.toastui-properate-calendar-month-day-name {
  font-size: 11px;
  text-transform: uppercase;
}
.toastui-calendar-day-name-item,
.toastui-calendar-timegrid {
  overflow: hidden;
}
.toastui-calendar-holiday-sun {
  color: #ff5129;
}
