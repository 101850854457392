.ant-input-number-suffix {
    margin: auto 0 !important;
    padding: 0 11px 0 4px;
    position: initial !important;
    transition: width 0.3s, padding 0.3s;
}

.ant-input-number-affix-wrapper {
    overflow: hidden;
}

.ant-input-number {
    display: flex;
}

.ant-input-number-affix-wrapper:hover .ant-input-number-handler-wrap {
    width: 50px !important;
}

.ant-input-number-handler-wrap {
    order: 2;
    position: unset !important;
    width: 0 !important;
}

.ant-input-number-affix-wrapper:hover .ant-input-number-suffix {
    width: 0;
    padding: 0;
}